<template>
  <div class="reset-password">
    <v-container class="content pb-5">
      <v-row>
        <v-col cols="12" md="5" class="hidden-sm-and-down">
          <v-img
            alt="img"
            class="img"
            src="@/assets/img/auth/img-1.jpg"
            max-width="600"
          />
        </v-col>

        <v-col cols="12" offset-md="1" md="6" align-self="center">
          <v-card color="pa-5 pa-md-8" rounded="xl">
            <h3 v-if="successfulPasswordReset" class="heading-h3">
              Go to login
            </h3>
            <h3 v-else class="heading-h3">Reset Password</h3>
            <v-form ref="form" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12" class="d-flex flex-column">
                  <!-- Password -->
                  <v-text-field
                    v-if="!successfulPasswordReset"
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    outlined
                    @click:append="showPassword = !showPassword"
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        Password <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>

                  <!-- Confirm Password -->

                  <v-text-field
                    v-if="!successfulPasswordReset"
                    v-model="confirmPassword"
                    :append-icon="
                      showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="confirmPasswordRules"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    label="Confirm Password"
                    counter
                    outlined
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        Confirm Password <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>

                  <v-btn
                    v-if="successfulPasswordReset"
                    :to="{ name: 'Login' }"
                    class="primary mt-4"
                    rounded
                  >
                    Login
                  </v-btn>

                  <v-btn
                    v-else
                    @click="validate()"
                    class="primary mt-4"
                    rounded
                  >
                    Send
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>

        <!-- Overlay -->
        <v-overlay :value="overlay" z-index="10">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",

  data: () => ({
    overlay: false,

    // redirect
    redirect: "/dashboard/inbox",

    token: "",

    successfulPasswordReset: false,

    showPassword: false,

    // password
    password: "",
    passwordRules: [
      (v) => !!v || "Required",
      (v) => v.length >= 8 || "Can not be less than 8 characters",
      (v) => v.length <= 30 || "Can not be more than 30 characters",
    ],
    passwordHasError: false,
    passwordErrorMessage: "password",

    showConfirmPassword: false,

    // confirm password
    confirmPassword: "",
    confirmPasswordRules: [
      (v) => !!v || "Required",
      // (v) => v === this.password || "The password confirmation does not match.",
    ],
    confirmPasswordHasError: false,
    confirmPasswordErrorMessage: "confirm password",
  }),

  computed: {},

  created() {
    let parameters = this.$route.query;

    if ("token" in parameters) {
      this.token = parameters.token;
    }
  },

  methods: {
    // validate
    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.resetPassword();
      }
    },

    // resetPassword
    async resetPassword() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "reset-password",
        {
          token: this.token,
          password: this.password,
          confirm_password: this.confirmPassword,
        },
        "Password reset successful, you can now login"
      );

      if (res.status == 200) {
        this.token = "";
        this.password = "";
        this.confirmPassword = "";

        this.successfulPasswordReset = true;

        this.$refs.form.resetValidation();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  // min-height: 100vh;
  .content {
    margin-top: 100px;

    @media only screen and (max-width: 600px) {
      margin-top: 80px;
    }
  }
}
</style>
